import { takeLatest, put, select, throttle } from 'redux-saga/effects';
import i18n from '../../i18n';
import { saveAs } from 'file-saver';
import {
  LOAD_PAYOUTS,
  loadPayoutsError,
  setPayouts,
  SET_AMOUNT_FILTER,
  SET_TYPE_FILTER,
  SET_STATUS_FILTER,
  SET_CALENDAR_SAGA,
  CLEAR_CALENDAR_FILTER,
  setPaginatorTotal,
  CHANGE_PAGINATOR_PAGE,
  setPaginatorCurrent,
  LOAD_PENDING_MANUAL_PAYOUTS,
  loadPendingManualPayoutsError,
  setPendingManualPayouts,
  LOAD_PAYOUT_FORM_DATA,
  setNewPayoutFormData,
  loadPayoutPlans,
  DOWNLOAD_PAYOUTS_CSV
} from './reducer';
import { getRequest, handleSagaError } from '../../utilities/requests';
import { history } from '../../utilities/history';
import { i18nMoment } from '../../utilities/dates';

const doPayouts = config => {
  const url = '/payouts';
  return getRequest(url, config);
};

const doDownloadPayoutsCsv = config => {
  const url = '/payouts.csv';
  return getRequest(url, config, { responseType: 'blob' });
};

function* handlePayoutFilters(action) {
  try {
    const settlement_method = yield select(
      state => state.payouts.filter.typeFilter
    );
    const status = yield select(state => state.payouts.filter.statusFilter);
    const perPage = yield select(state => state.payouts.filter.amountFilter);
    const calendarStart = yield select(
      state => state.payouts.filter.calendarStart
    );
    const calendarEnd = yield select(state => state.payouts.filter.calendarEnd);
    const currentPage = yield select(
      state => state.payouts.payouts.currentPage
    );

    const config = { per_page: perPage, current_page: currentPage };

    Object.assign(config, {
      settlement_method,
      status
    });

    if (calendarEnd) {
      config.calendar_start = handleI18nMoment(calendarStart);
      config.calendar_end = handleI18nMoment(calendarEnd);
    }

    if (action.downloadCsv) {
      yield handleDownloadPayouts(config);
    } else {
      yield handlePayouts(config, perPage);
    }
  } catch (error) {
    yield put(loadPayoutsError());
  }
}

// For saga requests, format needs to be in YY/MM/DD to work with backend. Do not localize.
const handleI18nMoment = data =>
  i18nMoment({
    data,
    type: 'format',
    additionalData: 'YY/MM/DD'
  });

function* handleDownloadPayouts(config) {
  try {
    const response = yield doDownloadPayoutsCsv(config);
    const time = i18nMoment({
      type: 'format',
      additionalData: 'Y-MM-DD'
    });
    saveAs(response.data, `payouts-${time}.csv`);
  } catch (error) {
    yield handleSagaError(error, i18n.t('payouts.modal.downloadPayoutsError'));
  }
}

function* handlePayouts(config, perPage) {
  const response = yield doPayouts(config);
  yield calculatePagination(response, perPage);
  yield put(setPayouts(response.data.filtered_payouts));
}

function* calculatePagination(response, perPage) {
  //should this be updated to reflect the latest  in transactions/saga.js
  if (response.data.all_payouts_total > perPage) {
    const unRoundedTotal = response.data.all_payouts_total / perPage;
    const totalPages = Math.ceil(unRoundedTotal);
    yield put(setPaginatorTotal(totalPages));
  } else {
    yield put(setPaginatorTotal(1));
  }

  if (response.data.filtered_payouts_total < perPage) {
    yield put(setPaginatorTotal(1));
  }

  if (response.data.reset_paginator) {
    yield put(setPaginatorCurrent(1));
  }
}

export function* payoutsSaga() {
  yield takeLatest(
    [
      LOAD_PAYOUTS,
      SET_AMOUNT_FILTER,
      SET_TYPE_FILTER,
      SET_STATUS_FILTER,
      SET_CALENDAR_SAGA,
      CLEAR_CALENDAR_FILTER,
      CHANGE_PAGINATOR_PAGE
    ],
    handlePayoutFilters
  );
}

export function* throttlePayoutsSaga() {
  yield throttle(5000, DOWNLOAD_PAYOUTS_CSV, handlePayoutFilters);
}

const fetchPendingManualPayouts = config => {
  const url = '/payouts/pending';
  return getRequest(url, config);
};

function* handleLoadPendingManualPayouts() {
  try {
    const response = yield fetchPendingManualPayouts();
    yield put(setPendingManualPayouts(response.data.pending_payouts));
  } catch (error) {
    yield put(loadPendingManualPayoutsError());
  }
}

export function* loadPendingManualPayouts() {
  yield takeLatest(LOAD_PENDING_MANUAL_PAYOUTS, handleLoadPendingManualPayouts);
}

const fetchNewPayoutData = (
  currencyId,
  selectedCurrencyIso,
  currencyNetworkId
) => {
  const url = `/payouts/new/${currencyId}`;
  const params = {
    payout_currency: selectedCurrencyIso,
    currency_network_id: currencyNetworkId
  };
  return getRequest(url, params);
};

function* handleLoadNewPayoutData(action) {
  try {
    const currencyId = action.iso.id;
    const selectedCurrencyIso = action.selectedCurrencyIso;
    const currencyNetworkId = action.currencyNetworkId;
    const payoutResponse = yield fetchNewPayoutData(
      currencyId,
      selectedCurrencyIso,
      currencyNetworkId
    );
    yield put(loadPayoutPlans());
    yield put(setNewPayoutFormData({ ...payoutResponse.data }));
  } catch (error) {
    if (
      error.response &&
      error.response.status < 500 &&
      error.response.data.details
    ) {
      yield handleSagaError(error);
      history.goBack();
    } else {
      history.push('/error');
    }
  }
}

export function* loadNewPayoutData() {
  yield takeLatest(LOAD_PAYOUT_FORM_DATA, handleLoadNewPayoutData);
}
