import { ROUTE_CHANGED } from '../reducer';

export const LOAD_SETTLEMENT_OPTIONS = 'LOAD_SETTLEMENT_OPTIONS';
export const SET_SETTLEMENT_OPTIONS = 'SET_SETTLEMENT_OPTIONS';
export const FAILED_SETTLEMENT_OPTIONS_LOAD = 'FAILED_SETTLEMENT_OPTIONS_LOAD';
export const SET_SUBMIT_SUCCEEDED = 'SettlementOptions/SET_SUBMIT_SUCCEEDED';

export const setSubmitSucceeded = () => ({
  type: SET_SUBMIT_SUCCEEDED
});
export const failedSettlementOptionsLoad = () => ({
  type: FAILED_SETTLEMENT_OPTIONS_LOAD
});
export const setSettlementOptions = payoutPlans => ({
  type: SET_SETTLEMENT_OPTIONS,
  payoutPlans
});
export const loadSettlementOptions = () => ({
  type: LOAD_SETTLEMENT_OPTIONS
});

const initialState = {
  payoutPlans: [],
  loading: true,
  failedLoad: false,
  submitSucceeded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTLEMENT_OPTIONS:
      return {
        ...state,
        payoutPlans: action.payoutPlans,
        loading: false
      };
    case FAILED_SETTLEMENT_OPTIONS_LOAD:
      return {
        ...state,
        failedLoad: true
      };
    case SET_SUBMIT_SUCCEEDED:
      return {
        ...state,
        submitSucceeded: true
      };
    case ROUTE_CHANGED:
      return {
        ...state,
        submitSucceeded: false
      };
    default:
      return state;
  }
};
