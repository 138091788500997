import { combineReducers } from 'redux';

import filter from './filter';
import payouts from './payouts';
import manualPayouts from './manualPayouts';
import newPayout from './newPayout';
import { LOAD_SETTLEMENT_OPTIONS } from '../../SettlementOptions/reducer';

export const TOGGLE_FILTER = 'PAYOUTS/TOGGLE_FILTER';
export const TOGGLE_EXPANDED_PAYOUTS = 'PAYOUTS/TOGGLE_EXPANDED_PAYOUTS';
export const SET_TYPE_FILTER = 'PAYOUTS/SET_TYPE_FILTER';
export const SET_STATUS_FILTER = 'PAYOUTS/SET_STATUS_FILTER';
export const SET_AMOUNT_FILTER = 'PAYOUTS/SET_AMOUNT_FILTER';
export const SET_CALENDAR_START_FILTER = 'PAYOUTS/SET_CALENDAR_START_FILTER';
export const SET_CALENDAR_END_FILTER = 'PAYOUTS/SET_CALENDAR_END_FILTER';
export const CLEAR_CALENDAR_FILTER = 'PAYOUTS/CLEAR_CALENDAR_FILTER';
export const TOGGLE_CALENDAR_FILTER = 'PAYOUTS/TOGGLE_CALENDAR_FILTER';
export const OPEN_FILTER_TOGGLE = 'PAYOUTS/OPEN_FILTER_TOGGLE';
export const SET_WINDOW_SIZE = 'PAYOUTS/SET_WINDOW_SIZE';
export const LOAD_PAYOUTS = 'PAYOUTS/LOAD_PAYOUTS';
export const LOAD_PAYOUTS_ERROR = 'PAYOUTS/LOAD_PAYOUTS_ERROR';
export const SET_PAYOUTS = 'PAYOUTS/SET_PAYOUTS';
export const SET_CALENDAR_SAGA = 'PAYOUTS/SET_CALENDAR_SAGA';
export const SET_PAGINATOR_TOTAL = 'PAYOUTS/SET_PAGINATOR_TOTAL';
export const SET_PAGINATOR_CURRENT = 'PAYOUTS/SET_PAGINATOR_CURRENT';
export const CHANGE_PAGINATOR_PAGE = 'PAYOUTS/CHANGE_PAGINATOR_PAGE';
export const PREVIOUSLY_VISITED = 'PAYOUTS/PREVIOUSLY_VISITED';
export const SET_PENDING_MANUAL_PAYOUTS = 'PAYOUTS/SET_PENDING_MANUAL_PAYOUTS';
export const LOAD_PENDING_MANUAL_PAYOUTS =
  'PAYOUTS/LOAD_PENDING_MANUAL_PAYOUTS';
export const LOAD_PENDING_MANUAL_PAYOUTS_ERROR =
  'PAYOUTS/LOAD_PENDING_MANUAL_PAYOUTS_ERROR';
export const LOAD_PAYOUT_FORM_DATA = 'PAYOUTS/LOAD_PAYOUT_FORM_DATA';
export const SET_NEW_PAYOUT_FORM_DATA = 'PAYOUTS/SET_NEW_PAYOUT_FORM_DATA';
export const SET_PAYOUT_OPTION = 'PAYOUTS/SET_PAYOUT_OPTION';
export const LOAD_PAYOUT_PLAN = 'PAYOUTS/LOAD_PAYOUT_PLAN';
export const SET_OTHER_WALLET_TYPE = 'PAYOUTS/SET_OTHER_WALLET_TYPE';
export const DOWNLOAD_PAYOUTS_CSV = 'PAYOUTS/DOWNLOAD_PAYOUTS_CSV';

export const setCalendarSaga = () => ({
  type: SET_CALENDAR_SAGA
});

export const toggleFilter = () => ({
  type: TOGGLE_FILTER
});

export const toggleExpandedPayouts = currentRow => ({
  type: TOGGLE_EXPANDED_PAYOUTS,
  currentRow
});

export const openFilterToggle = () => ({
  type: OPEN_FILTER_TOGGLE
});

export const setTypeFilter = typeFilter => ({
  type: SET_TYPE_FILTER,
  typeFilter
});

export const setStatusFilter = statusFilter => ({
  type: SET_STATUS_FILTER,
  statusFilter
});

export const setAmountFilter = amountFilter => ({
  type: SET_AMOUNT_FILTER,
  amountFilter
});

export const setCalendarStartFilter = calendarStart => ({
  type: SET_CALENDAR_START_FILTER,
  calendarStart
});

export const setCalendarEndFilter = calendarEnd => ({
  type: SET_CALENDAR_END_FILTER,
  calendarEnd
});

export const clearCalendarFilter = () => ({
  type: CLEAR_CALENDAR_FILTER
});

export const toggleCalendarFilter = () => ({
  type: TOGGLE_CALENDAR_FILTER
});

export const setWindowSize = windowSize => ({
  type: SET_WINDOW_SIZE,
  windowSize
});

export const loadPayouts = () => ({
  type: LOAD_PAYOUTS
});

export const loadPayoutsError = () => ({
  type: LOAD_PAYOUTS_ERROR
});

export const setPayouts = payoutsList => ({
  type: SET_PAYOUTS,
  payoutsList
});

export const setPaginatorTotal = paginatorTotal => ({
  type: SET_PAGINATOR_TOTAL,
  paginatorTotal
});

export const setPaginatorCurrent = currentPage => ({
  type: SET_PAGINATOR_CURRENT,
  currentPage
});

export const changePage = pageNumber => ({
  type: CHANGE_PAGINATOR_PAGE,
  pageNumber
});

export const setPendingManualPayouts = pendingPayouts => ({
  type: SET_PENDING_MANUAL_PAYOUTS,
  pendingPayouts
});

export const loadPendingManualPayouts = () => ({
  type: LOAD_PENDING_MANUAL_PAYOUTS
});

export const loadPendingManualPayoutsError = () => ({
  type: LOAD_PENDING_MANUAL_PAYOUTS_ERROR
});

export const loadPayoutFormData = (iso, selectedCurrencyIso, options = {}) => ({
  type: LOAD_PAYOUT_FORM_DATA,
  iso,
  selectedCurrencyIso,
  ...options
});

export const setNewPayoutFormData = payload => ({
  type: SET_NEW_PAYOUT_FORM_DATA,
  payload
});

export const setPayoutOption = payoutOption => ({
  type: SET_PAYOUT_OPTION,
  payoutOption
});

export const loadPayoutPlans = () => ({
  type: LOAD_SETTLEMENT_OPTIONS
});

export const setOtherWalletType = value => ({
  type: SET_OTHER_WALLET_TYPE,
  value
});

export const downloadPayoutsCsv = () => ({
  type: DOWNLOAD_PAYOUTS_CSV,
  downloadCsv: true
});

const reducer = combineReducers({
  payouts,
  filter,
  manualPayouts,
  newPayout
});

export default reducer;
